export default {
	IS_LEDGER_CONNECTED: 'IS_LEDGER_CONNECTED',
	REQUEST_PUBLIC_KEY: 'REQUEST_PUBLIC_KEY',
	GET_PUBLIC_KEY: 'GET_PUBLIC_KEY',
	GET_ACCOUNT: 'GET_ACCOUNT',
	GET_UNSIGNED_TRANSACTION: 'GET_UNSIGNED_TRANSACTION',
	SIGN_TRANSACTION: 'SIGN_TRANSACTION',
	SET_DERIVATION_PATH: 'SET_DERIVATION_PATH',
	RESET_UNSIGNED_TRANSACTION: 'RESET_UNSIGNED_TRANSACTION',
	SET_TRANSACTION_DATA: 'SET_TRANSACTION_DATA',
	RESET_ALL: 'RESET_ALL',
	GET_IS_KEYPAIR_VALID: 'GET_IS_KEYPAIR_VALID',
	SET_SIGN_TRANSACTION_KEYPAIR: 'SET_SIGN_TRANSACTION_KEYPAIR',
	SET_LOADER: 'SET_LOADER',
	SET_TERMS: 'SET_TERMS',
	SUBMIT_TRANSACTION: 'SUBMIT_TRANSACTION',
	RESET_TRANSACTION_DATA: 'RESET_TRANSACTION_DATA',
	// saga
	SET_IS_KEYPAIR_VALID: 'SET_IS_KEYPAIR_VALID',
	SET_IS_LEDGER_CONNECTED: 'SET_IS_LEDGER_CONNECTED',
	SET_PUBLIC_KEY: 'SET_PUBLIC_KEY',
	SET_ACCOUNT: 'SET_ACCOUNT',
	SET_UNSIGNED_TRANSACTION: 'SET_UNSIGNED_TRANSACTION',
	SET_SIGN_TRANSACTION: 'SET_SIGN_TRANSACTION',
	SIGN_TRANSACTION_KEYPAIR: 'SIGN_TRANSACTION_KEYPAIR',
	// solana
	RESET_PUBLIC_KEY_DATA: 'RESET_PUBLC_KEY',
	SET_SOLANA_PUBLIC_KEY: 'SET_SOLANA_PUBLIC_KEY',
	RESOLVE_TOKEN_ACCOUNTS: 'GET_TOKEN_ACCOUNTS',
	SET_TOKEN_ACCOUNTS: 'SET_TOKEN_ACCOUNTS',
	GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
	SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
	GET_SERVICE_CONFIG: 'GET_SERVICE_CONFIG',
	SET_SERVICE_CONFIG: 'SET_SERVICE_CONFIG',
	GET_RECENT_BLOCKHASH: 'GET_RECENT_BLOCKHASH',
	SET_RECENT_BLOCKHASH: 'SET_RECENT_BLOCKHASH',
	GET_SOLANA_TRANSACTION: 'GET_SOLANA_TRANSACTION',
	SET_SOLANA_TRANSACTION: 'SET_SOLANA_TRANSACTION',
	SIGN_AND_SUBMIT_TRANSACTION: 'SIGN_AND_SUBMIT_TRANSACTION',
	SIGN_AND_SUBMIT_TRANSACTION_LEDGER: 'SIGN_AND_SUBMIT_TRANSACTION_LEDGER',
	SET_SUBMITTED_TRANSACTION: 'SET_SUBMITTED_TRANSACTION',
	CREATE_TOKEN_ACCOUNT: 'CREATE_TOKEN_ACCOUNT',
	CREATE_TOKEN_ACCOUNT_LEDGER: 'CREATE_TOKEN_ACCOUNT_LEDGER',
	SET_ACCOUNT_UPDATE_REQUIRED: 'SET_ACCOUNT_UPDATE_REQUIRED'
};
