// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approve-payment-js": () => import("./../../../src/pages/approve-payment.js" /* webpackChunkName: "component---src-pages-approve-payment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-access-js": () => import("./../../../src/pages/key-access.js" /* webpackChunkName: "component---src-pages-key-access-js" */),
  "component---src-pages-ledger-js": () => import("./../../../src/pages/ledger.js" /* webpackChunkName: "component---src-pages-ledger-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-review-payment-js": () => import("./../../../src/pages/review-payment.js" /* webpackChunkName: "component---src-pages-review-payment-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-transaction-approved-js": () => import("./../../../src/pages/transaction-approved.js" /* webpackChunkName: "component---src-pages-transaction-approved-js" */),
  "component---src-pages-transaction-js": () => import("./../../../src/pages/transaction.js" /* webpackChunkName: "component---src-pages-transaction-js" */)
}

